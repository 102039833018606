import React, { FC, useEffect, useRef, useState } from "react";
import NextImage, { ImageProps } from "next/future/image";
import { getImageSrc } from "~lib";
import { Box, SystemProps, system } from "flicket-ui";
import { ExtendedFile, MetaData } from "~graphql/sdk";
import styled from "styled-components";
import { omit, pick } from "@styled-system/props";
import { ImageWrapper } from "../common.ImageWrapper";
import { ResponsiveValue } from "styled-system";

export interface ImageWrapperProps extends Omit<ImageProps, "src"> {
  src?: string;
  image?: ExtendedFile;
  style?: React.CSSProperties;
  fallback?: string;
}

export const StyledDiv = styled.div<SystemProps>`
  overflow: hidden;
  ${system}
`;

const hasMetaData = (bannerImage: ExtendedFile) =>
  bannerImage?.metaData?.width && bannerImage?.metaData?.height;

// This component uses next/image under the hood to render the image.
// The component works with images that have metadata, or it reads the
// width of the current container and uses that to supply to next/image
const Image: FC<ImageWrapperProps & SystemProps> = ({
  image,
  src,
  alt,
  style = {},
  fallback = "",
  ...rest
}) => {
  const wrapper = useRef<HTMLDivElement>();
  const [contentSize, setContentSize] = useState<MetaData>(image?.metaData);

  useEffect(() => {
    if (wrapper.current) {
      setContentSize({
        width: wrapper.current.offsetWidth,
        height: wrapper.current.offsetHeight,
      });
    }
  }, [wrapper.current]);

  const imageSrc = src ?? getImageSrc(image, fallback);

  if (!imageSrc) {
    return null;
  }

  return (
    <StyledDiv ref={wrapper} {...pick(rest)}>
      {contentSize && (
        <NextImage
          width={contentSize.width}
          height={contentSize.height}
          style={{
            width: "100%",
            height: "auto",
            ...style,
          }}
          src={imageSrc}
          alt={alt}
          {...omit(rest)}
        />
      )}
    </StyledDiv>
  );
};

interface IFixedSizeImageProps
  extends ImageWrapperProps,
    Omit<SystemProps, "height" | "width" | "color" | "fill" | "position"> {
  position?: "left" | "center" | "right" | "top" | "bottom";
  height: number;
  width: number;
}

function FixedSizeImage({
  image,
  src,
  alt,
  fallback = "",
  width,
  height,
  position = "left",
  ...props
}: IFixedSizeImageProps) {
  const imageSrc = src ?? getImageSrc(image, fallback);

  return (
    <ImageWrapper width={width} height={height} {...pick(props)}>
      <NextImage
        src={imageSrc}
        alt={alt}
        fill={true}
        style={{ objectFit: "contain", objectPosition: position }}
        {...omit(props)}
      />
    </ImageWrapper>
  );
}

interface IFixedRatioImageProps
  extends Omit<IFixedSizeImageProps, "width" | "height"> {
  /** ratio expressed a `"width / height"` string */
  ratio: string;
}

function FixedRatioImage({
  image,
  src,
  alt,
  fallback = "",
  ratio,
  position = "center",
  ...props
}: IFixedRatioImageProps & Omit<SystemProps, "position">) {
  const imageSrc = src ?? getImageSrc(image, fallback);

  if (!imageSrc) {
    return null;
  }

  return (
    <Box
      position="relative"
      overflow="hidden"
      style={{
        aspectRatio: ratio,
      }}
      height="auto"
      {...pick(props)}
    >
      <NextImage
        src={imageSrc}
        alt={alt}
        fill={true}
        style={{ objectFit: "cover", objectPosition: position }}
        {...omit(props)}
      />
    </Box>
  );
}

export default Image;
export { FixedSizeImage, FixedRatioImage };
